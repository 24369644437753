import logo from './imgs/fraiman-logo.png';
import banner from './imgs/jerusalem/j1.jpg';
import office from './imgs/fraiman-office.jpg';

import j1 from './imgs/jerusalem/j1.jpg';
import j2 from './imgs/jerusalem/j2.jpg';
import j3 from './imgs/jerusalem/j3.jpg';
import j4 from './imgs/jerusalem/j4.jpg';
import j5 from './imgs/jerusalem/j5.jpg';
import j6 from './imgs/jerusalem/j6.jpg';
import j7 from './imgs/jerusalem/j7.jpg';
import j8 from './imgs/jerusalem/j8.jpg';
import j9 from './imgs/jerusalem/j9.jpg';

export const imgs = {
   office,
   logo,
   home: {
      banner
   },
   jerusalem: {
      j1,
      j2,
      j3,
      j4,
      j5,
      j6,
      j7,
      j8,
      j9,
   
   }
}