import React from 'react'

export const Contact = () => {
   return (
      <div className='page contact'>
         <div className="banner">

         </div>
      </div>
   )
}
